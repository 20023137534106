.k-spinner--spin {
  --spinner-color: var(--color, #000);
  --spinner-color-secondary: var(--color-secondary, currentColor);
  --spinner-size: 64px;
  color: var(--spinner-color);
  font-size: var(--spinner-size);
  width: 1em;
  height: 1em;
  max-width: 1em;
  max-height: 1em;
  background-image: radial-gradient(circle, #0000 52%, currentColor 54% 65%, #0000 67%);
  background-repeat: no-repeat;
  border-radius: 50%;
  animation-name: k-spin;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: -.5s;
  display: inline-block;
  position: relative;
}

@keyframes k-spin {
  0% {
    -webkit-clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(0deg);
  }

  .85733% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(12.8571deg);
  }

  1.57799% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(25.7143deg);
  }

  2.19752% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(38.5714deg);
  }

  2.75146% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(51.4286deg);
  }

  3.27533% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(64.2857deg);
  }

  3.80466% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(77.1429deg);
  }

  4.375% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(90deg);
  }

  5.02187% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(102.857deg);
  }

  5.78079% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(115.714deg);
  }

  6.68732% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(128.571deg);
  }

  7.77697% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(141.429deg);
  }

  9.08528% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(154.286deg);
  }

  10.6478% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(167.143deg);
  }

  12.5% {
    -webkit-clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    transform: rotate(180deg);
  }

  13.3573% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(212.143deg);
  }

  14.078% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(244.286deg);
  }

  14.6975% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(276.429deg);
  }

  15.2515% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(308.571deg);
  }

  15.7753% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(340.714deg);
  }

  16.3047% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(372.857deg);
  }

  16.875% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(405deg);
  }

  17.5219% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(437.143deg);
  }

  18.2808% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(469.286deg);
  }

  19.1873% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(501.429deg);
  }

  20.277% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(533.571deg);
  }

  21.5853% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(565.714deg);
  }

  23.1478% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(597.857deg);
  }

  25% {
    -webkit-clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(630deg);
  }

  25.8573% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(642.857deg);
  }

  26.578% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(655.714deg);
  }

  27.1975% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(668.571deg);
  }

  27.7515% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(681.429deg);
  }

  28.2753% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(694.286deg);
  }

  28.8047% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(707.143deg);
  }

  29.375% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2turn);
  }

  30.0219% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(732.857deg);
  }

  30.7808% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(745.714deg);
  }

  31.6873% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(758.571deg);
  }

  32.777% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(771.429deg);
  }

  34.0853% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(784.286deg);
  }

  35.6478% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(797.143deg);
  }

  37.5% {
    -webkit-clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    transform: rotate(810deg);
  }

  38.3573% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(842.143deg);
  }

  39.078% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(874.286deg);
  }

  39.6975% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(906.429deg);
  }

  40.2515% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(938.571deg);
  }

  40.7753% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(970.714deg);
  }

  41.3047% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1002.86deg);
  }

  41.875% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1035deg);
  }

  42.5219% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1067.14deg);
  }

  43.2808% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1099.29deg);
  }

  44.1873% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1131.43deg);
  }

  45.277% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1163.57deg);
  }

  46.5853% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1195.71deg);
  }

  48.1478% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1227.86deg);
  }

  50% {
    -webkit-clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1260deg);
  }

  50.8573% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1272.86deg);
  }

  51.578% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1285.71deg);
  }

  52.1975% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1298.57deg);
  }

  52.7515% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1311.43deg);
  }

  53.2753% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1324.29deg);
  }

  53.8047% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1337.14deg);
  }

  54.375% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1350deg);
  }

  55.0219% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1362.86deg);
  }

  55.7808% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1375.71deg);
  }

  56.6873% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1388.57deg);
  }

  57.777% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1401.43deg);
  }

  59.0853% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1414.29deg);
  }

  60.6478% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(1427.14deg);
  }

  62.5% {
    -webkit-clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    transform: rotate(4turn);
  }

  63.3573% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(1472.14deg);
  }

  64.078% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1504.29deg);
  }

  64.6975% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1536.43deg);
  }

  65.2515% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1568.57deg);
  }

  65.7753% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1600.71deg);
  }

  66.3047% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1632.86deg);
  }

  66.875% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1665deg);
  }

  67.5219% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1697.14deg);
  }

  68.2808% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1729.29deg);
  }

  69.1873% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1761.43deg);
  }

  70.277% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1793.57deg);
  }

  71.5853% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1825.71deg);
  }

  73.1478% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1857.86deg);
  }

  75% {
    -webkit-clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1890deg);
  }

  75.8573% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1902.86deg);
  }

  76.578% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1915.71deg);
  }

  77.1975% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1928.57deg);
  }

  77.7515% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1941.43deg);
  }

  78.2753% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1954.29deg);
  }

  78.8047% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1967.14deg);
  }

  79.375% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1980deg);
  }

  80.0219% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(1992.86deg);
  }

  80.7808% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2005.71deg);
  }

  81.6873% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2018.57deg);
  }

  82.777% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2031.43deg);
  }

  84.0853% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2044.29deg);
  }

  85.6478% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(2057.14deg);
  }

  87.5% {
    -webkit-clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 17% 12%, 33.5% 6%);
    transform: rotate(2070deg);
  }

  88.3573% {
    -webkit-clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 8.5% 27.5%, 17% 12%, 33.5% 6%);
    transform: rotate(2102.14deg);
  }

  89.078% {
    -webkit-clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2134.29deg);
  }

  89.6975% {
    -webkit-clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 3% 60.5%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2166.43deg);
  }

  90.2515% {
    -webkit-clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2198.57deg);
  }

  90.7753% {
    -webkit-clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 19% 89%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2230.71deg);
  }

  91.3047% {
    -webkit-clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2262.86deg);
  }

  91.875% {
    -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2295deg);
  }

  92.5219% {
    -webkit-clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 68% 100%, 68% 100%, 68% 100%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2327.14deg);
  }

  93.2808% {
    -webkit-clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 81% 89%, 81% 89%, 81% 89%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2359.29deg);
  }

  94.1873% {
    -webkit-clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 94% 78%, 94% 78%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2391.43deg);
  }

  95.277% {
    -webkit-clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 97% 60.5%, 97% 60.5%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2423.57deg);
  }

  96.5853% {
    -webkit-clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 100% 43%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2455.71deg);
  }

  98.1478% {
    -webkit-clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 91.5% 27.5%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(2487.86deg);
  }

  to {
    -webkit-clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    clip-path: polygon(50% 50%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0 43%, 17% 12%, 33.5% 6%);
    transform: rotate(7turn);
  }
}

/*# sourceMappingURL=index.2a19afa8.css.map */
